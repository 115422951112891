.case-content {
  .case-content-item {
    padding: 80px 0;
    position: relative;
    display: flex;
    gap: 64px;
    align-items: flex-start;

    .case-content-item-left {
      max-width: 780px;

      .case-content-item-desc {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }

      .case-content-item-title {
        color: var(--Dark, #1f1f1f);
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 60px */
      }

      .case-content-item-subtitle {
        margin: 24px 0;
        color: #000;
        font-size: 32px;
        font-weight: 600;
        line-height: 140%; /* 44.8px */
      }
    }
  }
}

@arcoblue-6: #63C8DC;