.footer {
  margin-top: 80px;

  .footer-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 360px;
    padding: 0 160px;
    background: linear-gradient(
      to right,
      rgba(208, 232, 242, 1),
      rgba(115, 64, 157, 1)
    );

    .logo {
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .logo-text {
      box-sizing: border-box;
      margin-left: 16px;
      width: 150px;
      .logo-span {
        color: #000;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
      }
    }

    .footer-menu-list {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      text-align: right;

      .footer-menu-item {
        flex: 1;
        color: #d8d8d8;
        cursor: pointer;
      }
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 160px;
    background-color: #000;
    color: #ccc;
  }
}

@arcoblue-6: #63C8DC;