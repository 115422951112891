.price-intro {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 60px */
  padding-bottom: 72px;
}

.price-content {
  display: flex;
  padding: 32px 16px;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--Balck-500, #ccc);
  background: #fff;

  /* Cover Shadow */
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.35);

  .price-desc {
    margin: 0;
    padding: 0;
    list-style: none;

    .price-item-desc {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 8px;

      & > img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

@arcoblue-6: #63C8DC;