.solutions-resume {
  padding: 80px 0;

  .solutions-scope {
    color: var(--Parimary-Varient, #582785);
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }

  .solutions-title {
    color: #000;
    /* Headline2 */
    font-size: 48px;
    font-weight: 600;
    line-height: 125%; /* 60px */
  }

  .solutions-sub-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }

  .solutions-content {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;

    .solutions-content-left {
      padding-right: 160px;
      width: 800px;

      .solutions-content-left-top {
        margin-bottom: 48px;
      }

      .solutions-content-btn {
        display: flex;
        max-width: 455px;
        padding: 12px;
        margin-top: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Primary-Color, #743295);
        font-size: 32px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .solutions-content-item {
      width: 640px;
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 16px;
      .solutions-content-item-feature {
        color: var(--Parimary-Varient, #582785);
        font-size: 20px;
        font-style: italic;
        font-weight: 700;
        line-height: 140%; /* 28px */
        white-space: nowrap;
      }

      .solutions-content-item-title {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%; /* 28px */
        margin-bottom: 24px;
      }

      .solutions-content-item-content {
        display: flex;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
      }
      .solutions-content-li {
        max-width: 260px;
      }

      .solutions-content-li-title {
        color: #fff;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        display: inline-block;
        padding: 4px 12px;
        margin-bottom: 8px;
        align-items: center;
        border-radius: 8px;
        background: var(--Parimary-Varient, #582785);
      }
    }
  }
}

@arcoblue-6: #63C8DC;