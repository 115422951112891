.anna-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  &.primary {
    color: #fff;
    background: #743295;
  }
  &.outline {
    color: #743295;
    border: 1px solid #743295;
    background: #fff;
  }
}

@arcoblue-6: #63C8DC;