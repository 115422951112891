@font-face {
  font-family: "BAHNSCHRIFT";
  src: local("BAHNSCHRIFT"),
    url(./assets/fonts/BAHNSCHRIFT.TTF) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --navbar-height: 64px;
  --title-margin: 0.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.arco-row {
  margin: 0 !important;
}
