.about-team {
  .team-item {
    display: flex;
    justify-content: space-between;
    margin-top: 72px;
    padding-top: 24px;
    border-top: 1px solid #000;
    gap: 200px;
    position: relative;
    align-items: flex-start;

    img {
      width: 253px;
      height: 245px;
    }
  }
}

@arcoblue-6: #63C8DC;