.layout {
  width: 100%;
  height: 100%;
  background: var(--color-white);
  flex-direction: row !important;
}

.layout-content {
  background: var(--color-white);
  margin-top: var(--navbar-height);
}

@arcoblue-6: #63C8DC;