.case-banner {
  height: 740px;
  background-image: url(../../../../assets/v2/solutions-banner.jpg);
  background-size: cover;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 66px;

  .banner-content {
    max-width: 900px;
    color: #000;

    .banner-title {
      font-feature-settings: "liga" off, "clig" off;
      font-size: 16px;
      font-style: italic;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      text-transform: uppercase;
    }

    .banner-subtitle {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 80px */
    }

    .banner-btns {
      margin-top: 32px;
      display: flex;
      gap: 8px;
    }
  }
}

@arcoblue-6: #63C8DC;