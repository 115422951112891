.case-direction {
  .case-direction-title {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 80px */
  }

  .case-direction-desc {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 40px */
    padding: 24px 0;
  }

  .case-direction-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
    color: #ccc;
    border-bottom: 1px solid #000;
    padding-bottom: 16px;

    .case-direction-btn-item {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      cursor: pointer;

      &.active {
        color: #000;
      }
    }
  }
}

@arcoblue-6: #63C8DC;